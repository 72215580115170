export default class ServerConfig {
	public static GetGtmId() {
		return process.env['GTM_ID'] ?? '';
	}
	public static GetRecaptchaSiteKey() {
		return process.env['RECAPTCHA_SITE_KEY'] ?? '';
	}
	public static GetFreeResourcesProgrammeId() {
		return process.env['PROGRAMME_FREE_RESOURCES'] ?? '';
	}
	public static GetDrPamWebsiteFaqCategoryId() {
		return process.env['CATEGORY_DR_PAM_WEBSITE_FAQS'] ?? '';
	}
	public static GetDrPamWebsiteBlogCategoryId() {
		return process.env['CATEGORY_DR_PAM_WEBSITE_BLOG'] ?? '';
	}
	public static GetDrPamInstagramUrl() {
		return process.env['URL_DR_PAM_INSTAGRAM'] ?? '';
	}
	public static GetDrPamFacebookUrl() {
		return process.env['URL_DR_PAM_FACEBOOK'] ?? '';
	}
	public static GetDrPamLinkedinUrl() {
		return process.env['URL_DR_PAM_LINKEDIN'] ?? '';
	}
	public static GetNdcWebsiteUrl() {
		return process.env['URL_NDC_WEBSITE'] ?? '';
	}
	public static GetPspWebsiteUrl() {
		return process.env['URL_PSP_WEBSITE'] ?? '';
	}
	public static GetDrPamWebsiteUrl() {
		return process.env['URL_DR_PAM_WEBSITE'] ?? '';
	}
	public static GetAppRouterDir() {
		return process.env['APP_ROUTER_DIR'] ?? '';
	}
	public static GetNdcEvidenceBaseProgrammeId() {
		return process.env['PROGRAMME_NDC_EVIDENCE_BASE'] ?? '';
	}
	public static GetFindNdcPractitionerUrl() {
		return process.env['URL_NDC_FIND_A_PRACTITIONER'] ?? '';
	}
	public static GetGenerationsMedicalUrl() {
		return process.env['URL_GENERATIONS_MEDICAL'] ?? '';
	}
	public static GetDutchEditionBabyBooklUrl() {
		return process.env['URL_DUTCH_EDITION_BABY_BOOK'] ?? '';
	}
	public static GetRevisedEditionBabyBooklUrl() {
		return process.env['URL_REVISED_BABY_BOOK'] ?? '';
	}
	public static GetPrivacyPolicyArticleId() {
		return process.env['ARTICLE_PRIVACY_POLICY'] ?? '';
	}
	public static GetSocialEnterpriseArticleId() {
		return process.env['ARTICLE_SOCIAL_ENTERPRISE'] ?? '';
	}
	public static GetTermsAndConditionsArticleId() {
		return process.env['ARTICLE_TERMS_AND_CONDITIONS'] ?? '';
	}
	public static GetEnvironment() {
		return process.env['ENVIRONMENT'] ?? '';
	}
}
